<template>
    <div v-if="popup && show" class="popup-layer" :style="popupStyle">
        <div class="popup-layer__inner">
            <v-card class="popup-layer__body" :href="popup.url" target="_blank">
                <div class="popup-layer__content" v-html="popup.content.replace(/\n/g, '<br>')"></div>
            </v-card>
            <div class="popup-layer__foot">
                <v-row no-gutters align="center" justify="space-between">
                    <v-col cols="auto">
                        <v-checkbox v-model="doNotOpenToday" :value="true" hide-details class="white--text">
                            <template #label><span class="white--text">오늘 하루 열지 않음</span></template>
                        </v-checkbox>
                    </v-col>
                    <v-col cols="auto">
                        <v-btn text color="white" class="v-btn--text-fit font-weight-regular" @click="close"><span class="font-size-14">닫기</span></v-btn>
                    </v-col>
                </v-row>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        popup: { type: Object, default: null },
    },
    data() {
        return {
            doNotOpenToday: false,
            show: false,
        };
    },
    mounted() {
        this.show = !this.$cookies.get(this.popup?._id);
    },
    methods: {
        close() {
            if (this.doNotOpenToday) {
                this.$cookies.set(this.popup._id, "hidden", "1d");
            }
            this.show = false;
        },
    },
    computed: {
        popupStyle() {
            return {
                left: this.popup.left + "px",
                top: this.popup.top + "px",
                width: this.popup.width + "px",
                height: this.popup.height + "px",
            };
        },
    },
};
</script>

<style lang="scss" scoped>
/* Popup */
.popup-layer {
    position: absolute;
    z-index: 999;
    background-color: #fff;
    &__inner {
        height: 100%;
    }
    &__body {
        display: block;
        width: 100% !important;
        height: 100% !important;
        border-radius: 0 !important;
    }
    &__content {
        height: 100%;
        line-height: 0;
    }
    &__foot {
        padding: 6px 12px;
        background-color: var(--v-grey-darken3);
    }
}
@media (max-width: 576px) {
    .popup-layer {
        max-width: calc(100vw - var(--container-gutter) * 2);
        height: auto !important;
        top: var(--page-section-padding-y) !important;
        left: var(--container-gutter) !important;
    }
}
</style>